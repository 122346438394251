// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk('appDeviceType/getData', async params => {
  console.log(params)
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  const response = await axios.get('/api/devicetype/get', {params, headers: { Authorization: `Bearer ${token}` }})
  console.log(response)
  return {
    params,
    data: response.data.data.alldata,
    allData: response.data.data.alldata,
    totalItems: response.data.data.totalItems,
    totalPages : response.data.data.totalPages
  }
})

export const deleteDeviceType = createAsyncThunk('appDeviceType/deleteDeviceType', (id, { dispatch, getState }) => {
  console.log(id)
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  axios.delete(`/api/devicetype/delete`, {params: {id}, headers: { Authorization: `Bearer ${token}` }})
  .then(res => {
    if (res.data.success) {
      alert(`Device type deleted successfully.`)
      dispatch(getData(getState().devicetypes.params))
    } else {
      alert(`Couldn't delete device type.`)
    }
  })

  return id
})

export const appDeviceType = createSlice({
  name: 'appDeviceType',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.totalItems = action.payload.totalItems
      state.params = action.payload.params
    })
  }
})

export default appDeviceType.reducer
