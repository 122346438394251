// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk('appRoles/getData', async params => {
  console.log(params)
     const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  const response = await axios.get('/api/role/get', /*params.route ? params.route : '/api/roles/getAll'*/ { params, headers: { Authorization: `Bearer ${token}`  }})
  console.log(response)
  return {
    params,
    data: response.data.data.alldata,
    allData: response.data.data.allData,
    totalPages : response.data.data.totalPages,
    totalItems : response.data.data.totalItems
  }
})

export const deleteRole = createAsyncThunk('appRoles/deleteRole', async (id, { dispatch, getState }) => {
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
 const res = await axios.delete(`/api/device/delete/${row.id}`, { headers: { Authorization: `Bearer ${token}` } })
  console.log(res)
  await dispatch(getData(getState().invoice.params))
  return id
})

export const appInvoiceSlice = createSlice({
  name: 'appRoles',
  initialState: {
    data: [],
    total: 0,
    totalItems: 0,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.totalItems = action.payload.totalItems
      state.params = action.payload.params
    })
  }
})

export default appInvoiceSlice.reducer
