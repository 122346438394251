// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import email from '@src/views/apps/email/store'
import devices from '@src/views/apps/device/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'

import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import devicetypes from '@src/views/apps/devicetype/store'
import roles from '@src/views/apps/roles/store'
import places from '@src/views/apps/cities/store'
import circles from '@src/views/apps/circle/store'
import getStates from '@src/views/apps/states/store'
import getZones from '@src/views/apps/zones/store'
import getSubcircles from '@src/views/apps/zones/store'
import getCmps from '@src/views/apps/cmp/store'
import getCountries from '@src/views/apps/countries/store'
import countries from '@src/views/superadmin/Components/addCountryStore'
import alarms from '@src/views/superadmin/Alarm/store'
import states from '@src/views/superadmin/Components/addStateStore'
import firmwares from '@src/views/superadmin/Firmware/store'
import activity from '@src/views/superadmin/Activity/store'
const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  devices,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  devicetypes,
  places,
  circles,
  getStates,
  getZones,
  getSubcircles,
  getCmps,
  getCountries,
  countries,
  states,
  firmwares,
  activity,
  alarms,
  roles
}

export default rootReducer
