import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchZoneData = createAsyncThunk(
  'zone/fetchZoneData',
  async (_, { rejectWithValue }) => {
    try {
      const userdata = JSON.parse(localStorage.getItem('userData'));
      const token = userdata ? userdata.accessToken : '';
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/zone/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteZone = createAsyncThunk(
  'zone/deleteZone',
  async (id, { rejectWithValue }) => {
    try {
      const userdata = JSON.parse(localStorage.getItem('userData'));
      const token = userdata ? userdata.accessToken : '';
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/zone/delete?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const zoneSlice = createSlice({
  name: 'zone',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchZoneData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchZoneData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchZoneData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteZone.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteZone.fulfilled, (state, action) => {
        state.data = state.data.filter((item) => item.id !== action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteZone.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export default zoneSlice.reducer;
export const zoneActions = { fetchZoneData, deleteZone };