// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../../apps/axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk('appAlarm/getData', async params => {
  console.log(params)
  
  const response = await axios.get(`/api/alarmconfig/get`, { params})
  console.log(response)
  return {
    params,
    data: response.data.data.alldata,
    allData: response.data.data.alldata,
    totalPages : response.data.data.totalPages,
    totalItems : response.data.data.totalItems
  }
})

export const deleteAlarm = createAsyncThunk('appAlarm/deleteAlarm', async (id, { dispatch, getState }) => {
  console.log(id)
  const res = await axios.delete(`/api/alarmconfig/delete?id=${id}`)
  console.log(res)
  if (res.data.success) {
    alert(`Alarm deleted successfully.`)
    dispatch(getData(getState().alarms.params))
  } else {
    alert(`Alarm couldn't be deleted.`)
  }
  return id
})

export const appInvoiceSlice = createSlice({
  name: 'appAlarm',
  initialState: {
    data: [],
    total: 0,
    totalItems: 0,
    params: {},
    allData: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.totalItems = action.payload.totalItems
      state.params = action.payload.params
    })
  }
})

export default appInvoiceSlice.reducer
