// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "../../apps/axios";

export const getAllStates = createAsyncThunk(
  "appState/getAllStates",
  async (country = -1) => {
    const allStatesTemp = [];
    const userdata = JSON.parse(localStorage.getItem("userData"));
    const token = userdata ? userdata.accessToken : "";
    const response = await axios.get(`api/state/getAll?country=${country}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response);
    if (response.data.success && response.data.data.rows.length > 0) {
      response.data.data.rows.forEach((state) => {
        allStatesTemp.push({
          value: state.id,
          label: state.name,
        });
      });

      return allStatesTemp;
    } else {
      console.log(`no states found`);
    }
  }
);

export const addNewState = createAsyncThunk("appState/postState", (data) => {
  console.log(data);
  return data;
});

export const fillStates = createAsyncThunk("appState/fillStates", (data) => {
  console.log(data);
  return data;
});

export const statesSlice = createSlice({
  name: "states",
  initialState: {
    allStates: [],
    newlyCreatedState: {
      value: "",
      label: "",
    },
    newStateAddedFrom: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNewState.fulfilled, (state, action) => {
        state.allStates = [
          ...state.allStates,
          {
            value: action.payload.newState.id,
            label: action.payload.newState.name,
          },
        ];

        state.newlyCreatedState = {
          ...state.newlyCreatedState,
          value: action.payload.newState.id,
          label: action.payload.newState.name,
        };

        state.newStateAddedFrom = action.payload.component;
      })
      .addCase(getAllStates.fulfilled, (state, action) => {
        state.allStates = action.payload;
      })
      .addCase(fillStates.fulfilled, (state, action) => {
        state.allStates = action.payload;
      });
  },
});

export default statesSlice.reducer;
