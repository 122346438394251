// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "../../axios";
// import { store } from "@store/store";
// export const getData = createAsyncThunk('appDevice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk("appDevice/getData", async (params) => {
  console.log("get devices");

  // const tmp = {value : 38, label :'SNMP'}
  // localStorage.setItem('device_type', tmp)
  const tmpdevice = JSON.parse(localStorage.getItem("device_type"));
  const userdata = JSON.parse(localStorage.getItem("userData"));
  const token = userdata ? userdata.accessToken : "";
  const paramsData = { ...params };

  if (tmpdevice.value) {
    paramsData.filterValue = tmpdevice.value;
  }
  if (paramsData.status.value === "") {
    paramsData.status = { value: tmpdevice.value, label: tmpdevice.label };
  }

  const response = await axios.get("/api/device/get", {
    params: paramsData,
    headers: { Authorization: `Bearer ${token}` },
  });
  console.log(response);

  return {
    params,
    data: response.data.data.alldata,
    alldata: response.data.data.alldata,
    totalItems: response.data.data.totalItems,
    totalPages: response.data.data.totalPages,
  };
});

export const deleteDevice = createAsyncThunk(
  "appDevice/deleteDevice",
  async (id, { dispatch, getState }) => {
    const userdata = JSON.parse(localStorage.getItem("userData"));
    const token = userdata ? userdata.accessToken : "";
    //  const  currentUser = localStorage.getItem("userData")

    axios
      .delete(`/api/device/delete?id=${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        console.log(res);
        if (res.data.success) {
          alert(`Device deleted successfully.`);
          dispatch(getData(getState().devices.params));
        }
      });

    return id;
  }
);

export const appDeviceSlice = createSlice({
  name: "appDevice",
  initialState: {
    data: [],
    total: 0,
    totalItems: 0,
    params: {},
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      console.log(action);
      state.data = action.payload.data;
      state.allData = action.payload.alldata;
      state.total = action.payload.totalPages;
      state.totalItems = action.payload.totalItems,
      state.params = action.payload.params;
    });
  },
});

export default appDeviceSlice.reducer;
