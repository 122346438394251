// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "../../../apps/axios";

// export const getData = createAsyncThunk('appFirmware/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.total
//   }
// })

export const getData = createAsyncThunk(
  "appFirmware/getData",
  async (params) => {
    const userdata = JSON.parse(localStorage.getItem("userData"));
    const token = userdata ? userdata.accessToken : "";
    const deviceType = JSON.parse(localStorage.getItem("device_type"));
    console.log(deviceType);
    if (userdata.role === "admin") params.device_type = deviceType.value;
    const response = await axios.get(`api/firmware/get`, {
      params: params,
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response);
    return {
      params,
      data: response.data.data.alldata,
      allData: response.data.data.alldata,
      totalPages: response.data.data.totalPages,
      totalItems: response.data.data.totalItems,
    };
  }
);

export const deleteFirmware = createAsyncThunk(
  "appFirmware/deleteFirmware",
  async (id, { dispatch, getState }) => {
    console.log(id);
    const userdata = JSON.parse(localStorage.getItem("userData"));
    const token = userdata ? userdata.accessToken : "";
    const res = await axios.delete(`/api/firmware/delete`, {
      params: { id },
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.data.success) {
      alert(`Firmware deleted successfully.`);
      dispatch(getData(getState().firmwares.params));
    }

    return id;
  }
);

export const appFirmware = createSlice({
  name: "appFirmware",
  initialState: {
    data: [],
    totalItems: 0,
    total: 0,
    params: {},
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getData.fulfilled, (state, action) => {
      console.log(action);
      state.data = action.payload.data;
      state.allData = action.payload.allData;
      state.total = action.payload.totalPages;
      state.totalItems = action.payload.totalItems;
      state.params = action.payload.params;
    });
  },
});

export default appFirmware.reducer;
