import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchStateData = createAsyncThunk(
  'state/fetchStateData',
  async (_, { rejectWithValue }) => {
    try {
      const userdata = JSON.parse(localStorage.getItem('userData'));
      const token = userdata ? userdata.accessToken : '';
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/state/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteState = createAsyncThunk(
  'state/deleteState',
  async (id, { rejectWithValue }) => {
    try {
      const userdata = JSON.parse(localStorage.getItem('userData'));
      const token = userdata ? userdata.accessToken : '';
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/state/delete?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return id; // Return the deleted state id
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const stateSlice = createSlice({
  name: 'state',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStateData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStateData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchStateData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteState.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteState.fulfilled, (state, action) => {
        state.data = state.data.filter((item) => item.id !== action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteState.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export default stateSlice.reducer;
export const stateActions = { fetchStateData, deleteState };
