// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "../../axios";

export const setProducts = createAsyncThunk(
  "dataGrid/getdata",
  async (data) => {
    const params = {
      page: 1,
      limit: 10,
      sortColumn: "id",
      search: "",
      filterBy: "",
      filterValue: "",
    };

    return { params, data: data.alldata };
  }
);

export const getProducts = createAsyncThunk(
  "dataGrid/getdata",
  async (params) => {
    const userdata = JSON.parse(localStorage.getItem("userData"));
    const token = userdata ? userdata.accessToken : "";

    // Construct query parameters
    const queryParams = new URLSearchParams({
      sort: params.sort || 'desc',
      sortColumn: params.sortColumn || 'id',
      page: params.page || 1,
      limit: params.limit || 10,
      filterValue: params.filterValue || 42,
      device_type: params.deviceType || 42,
      ...(params.q && { search: params.q }),
      ...(params.status && { status: JSON.stringify(params.status) }),
      ...(params.filterBy && { filterBy: params.filterBy }),
      ...(params.filters && { filters: JSON.stringify(params.filters) }),
    });


    queryParams.forEach((value, key) => {
      if (value === undefined || value === null || value === '') {
        queryParams.delete(key);
      }
    });

    try {
      const response = await axios.get(
        `/api/device/get?${queryParams}`,
        { 
          headers: { Authorization: `Bearer ${token}` }
        }
      );

      return { params, data: response.data };
    } catch (error) {
      // Handle error
      console.error('Error fetching products:', error);
      throw error;
    }
  }
);

export const addToCart = createAsyncThunk(
  "appEcommerce/addToCart",
  async (id, { dispatch, getState }) => {
    const response = await axios.post("/apps/ecommerce/cart", {
      productId: id,
    });
    await dispatch(getProducts(getState().ecommerce.params));
    return response.data;
  }
);

export const getWishlistItems = createAsyncThunk(
  "appEcommerce/getWishlistItems",
  async () => {
    const response = await axios.get("/apps/ecommerce/wishlist");
    return response.data;
  }
);

export const deleteWishlistItem = createAsyncThunk(
  "appEcommerce/deleteWishlistItem",
  async (id, { dispatch }) => {
    const response = await axios.delete(`/apps/ecommerce/wishlist/${id}`);
    dispatch(getWishlistItems());
    return response.data;
  }
);

export const getCartItems = createAsyncThunk(
  "appEcommerce/getCartItems",
  async () => {
    const response = await axios.get("/apps/ecommerce/cart");
    return response.data;
  }
);

export const getProduct = createAsyncThunk(
  "appEcommerce/getProduct",
  async (slug) => {
    const response = await axios.get(`/apps/ecommerce/products/${slug}`);
    return response.data;
  }
);

export const addToWishlist = createAsyncThunk(
  "appEcommerce/addToWishlist",
  async (id) => {
    await axios.post("/apps/ecommerce/wishlist", { productId: id });
    return id;
  }
);

export const deleteCartItem = createAsyncThunk(
  "appEcommerce/deleteCartItem",
  async (id, { dispatch }) => {
    await axios.delete(`/apps/ecommerce/cart/${id}`);
    dispatch(getCartItems());
    return id;
  }
);

export const appEcommerceSlice = createSlice({
  name: "ecommerce",
  initialState: {
    params: {},
    products: [],
    totalProducts: 0,
    productDetail: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setProducts.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.params = action.payload.params;
          state.products = action.payload.data.data.alldata;
          state.totalProducts = action.payload.data.data.totalItems;
        }
      })
      .addCase(getWishlistItems.fulfilled, (state, action) => {
        state.wishlist = action.payload.products;
      })
      .addCase(getCartItems.fulfilled, (state, action) => {
        state.cart = action.payload.products;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.productDetail = action.payload.product;
      });
  },
});

export default appEcommerceSlice.reducer;
