import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchCmpData = createAsyncThunk(
  'cmp/fetchCmpData',
  async (_, { rejectWithValue }) => {
    try {
      const userdata = JSON.parse(localStorage.getItem('userData'));
      const token = userdata ? userdata.accessToken : '';
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/cmp/getAll`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCmp = createAsyncThunk(
  'cmp/deleteCmp',
  async (id, { rejectWithValue }) => {
    try {
      const userdata = JSON.parse(localStorage.getItem('userData'));
      const token = userdata ? userdata.accessToken : '';
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/cmp/delete?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cmpSlice = createSlice({
  name: 'cmp',
  initialState: {
    data: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCmpData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCmpData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchCmpData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      })
      .addCase(deleteCmp.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCmp.fulfilled, (state, action) => {
        state.data = state.data.filter((item) => item.id !== action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteCmp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.message;
      });
  },
});

export default cmpSlice.reducer;
export const cmpActions = { fetchCmpData, deleteCmp };