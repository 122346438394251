// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.totalItems
//   }
// })

export const getCircleData = createAsyncThunk('appCircle/getData', async params => {
  console.log(params)
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
    const tmpdevice = JSON.parse(localStorage.getItem('device_type'))
 
  if (tmpdevice.value !== null)  params.filterValue = tmpdevice.value
 console.log(params)

  const response = await axios.get(`api/area/get`, { params, headers: { Authorization: `Bearer ${token}` }})
  console.log(response)
  return {
    params,
    data: response.data.data.alldata,
    allData: response.data.data.alldata,
    totalPages : response.data.data.totalPages,
    totalItems : response.data.data.totalItems
  }
}) 

export const deleteCircle = createAsyncThunk('appCircle/deleteCircle', async (id, { dispatch, getState }) => {
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  try {
    axios.delete(`/api/area/delete?id=${id}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(res => {
      console.log(res)
      if (res.data.success) {
        alert(`Circle deleted successfully.`)
        dispatch(getCircleData(getState().circles.params))
      } else {
        console.log(res)
        console.log(44)
        alert(`Circle couldn't be deleted.`)
      }
    })
    .catch(() => {
      console.log(48)
      alert(`Circle couldn't be deleted.`)
    })
  } catch (err) {
    console.log(52)
    alert(`Circle couldn't be deleted.`)
  }
  
  return id
})

export const appCircleSlice = createSlice({
  name: 'appCircle',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    newlyCreatedPlace: {}
  },
  reducers: {
    addNewCircleReducer: (state, newCircle) => {
      state.allData = [
        ...state.allData,
        newCircle.payload
      ]

      state.newlyCreatedCircle = {
        ...state.newlyCreatedCircle,
        value: newCircle.payload.id,
        label: newCircle.payload.name
      }
      // console.log(state.allData, newPlace, abc)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getCircleData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.totalItems = action.payload.totalItems
      state.params = action.payload.params
    })
  }
})

export default appCircleSlice.reducer
export const {addNewCircleReducer} = appCircleSlice.actions
