// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../axios'


// export const getData = createAsyncThunk('appInvoice/getData', async params => {
//    console.log(params)
//   const response = await axios.get('/apps/invoice/invoices', params)
//     console.log(response)
//   return {
//     params,
//     data: response.data.invoices,
//     allData: response.data.allData,
//     totalPages: response.data.totalItems
//   }
// })

export const getCountryData = createAsyncThunk('appCountry/getData', async params => {
  const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  console.log(params)
    const tmpdevice = JSON.parse(localStorage.getItem('device_type'))
  if (tmpdevice.value !== null)  params.filterValue = tmpdevice.value
 console.log(params)

  const response = await axios.get(`api/country/get`, { params, headers: { Authorization: `Bearer ${token}` }})
  console.log(response)
  return {
    params,
    data: response.data.data.alldata,
    allData: response.data.data.alldata,
    totalPages : response.data.data.totalPages,
    totalItems : response.data.data.totalItems
  }
})

export const deleteCountry = createAsyncThunk('appCountry/deleteCountry', async (id, { dispatch, getState }) => {
  const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  try {
    axios.delete(`/api/country/delete?id=${id}`, { headers: { Authorization: `Bearer ${token}` } })
    .then(res => {
      console.log(res)
      if (res.data.success) {
        alert(`Country deleted successfully.`)
        dispatch(getCountryData(getState().countries.params))
      } else {
        console.log(res)
        console.log(44)
        alert(`Country couldn't be deleted.`)
      }
    })
    .catch(() => {
      console.log(48)
      alert(`Country couldn't be deleted.`)
    })
  } catch (err) {
    console.log(52)
    alert(`Country couldn't be deleted.`)
  }
  
  return id
})

export const appCountrySlice = createSlice({
  name: 'appCountry',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    newlyCreatedCountry: {}
  },
  reducers: {
    addNewCountryReducer: (state, newCountry) => {
      state.allData = [
        ...state.allData,
        newCountry.payload
      ]

      state.newlyCreatedCountry = {
        ...state.newlyCreatedCountry,
        value: newCountry.payload.id,
        label: newCountry.payload.name
      }
      // console.log(state.allData, newPlace, abc)
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getCountryData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.allData = action.payload.allData
      state.total = action.payload.totalPages
      state.totalItems = action.payload.totalItems
      state.params = action.payload.params
    })
  }
})

export default appCountrySlice.reducer
export const {addNewCountryReducer} = appCountrySlice.actions
