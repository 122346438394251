// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "../../apps/axios";

export const getAllCountries = createAsyncThunk(
  "appCountry/getAllCountries",
  async () => {
    const allCountriesTemp = [];
    const userdata = JSON.parse(localStorage.getItem("userData"));
    const token = userdata ? userdata.accessToken : "";
    const response = await axios.get(`/api/country/getAll`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (response.data.success && response.data.data.rows.length > 0) {
      response.data.data.rows.forEach((state) => {
        allCountriesTemp.push({
          value: state.id,
          label: state.name,
        });
      });

      return allCountriesTemp;
    } else {
      console.log(`no countries found!!!`);
    }
  }
);

export const addNewCountry = createAsyncThunk(
  "appCountry/postCountry",
  (data) => {
    console.log(data);
    return data;
  }
);

export const fillCountries = createAsyncThunk(
  "appCountry/fillCountries",
  (data) => {
    console.log(data);
    return data;
  }
);

export const countriesSlice = createSlice({
  name: "countries",
  initialState: {
    allCountries: [],
    newlyCreatedCountry: {
      value: "",
      label: "",
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addNewCountry.fulfilled, (state, action) => {
        if (state.allCountries !== undefined) {
          state.allCountries = [
            ...state.allCountries,
            {
              value: action.payload.id,
              label: action.payload.name,
            },
          ];
        }

        state.newlyCreatedCountry = {
          ...state.newlyCreatedCountry,
          value: action.payload.id,
          label: action.payload.name,
        };
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.allCountries = action.payload;
      })
      .addCase(fillCountries.fulfilled, (state, action) => {
        state.allCountries = action.payload;
      });
  },
});

export default countriesSlice.reducer;
