import axios from "axios";


// const instance = axios.create({ baseURL: "https://backend.copt.ssdemo.xyz" });
const instance = axios.create({ baseURL: process.env.REACT_APP_API_URL });
// const instance = axios.create({ baseURL: "https://noc.gallerindia.com/api" });
// const instance = axios.create({ baseURL: "http://127.0.0.1:5001" });

// const instance = axios.create({
//   baseURL: `${
//     window.location.hostname === "localhost" ? "http://" : "https://"
//   }${window.location.hostname}:5001`,
// });

export default instance;
