// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from '../../axios'

export const getAllData = createAsyncThunk('appUsers/getAllData', async () => {
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  const response = await axios.get('/api/auth/getAll', {headers: { Authorization: `Bearer ${token}` }})
  console.log(response)
  return response.data.data.allData
})

export const getData = createAsyncThunk('appUsers/getData', async params => {
  console.log(params)
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''

  const userData = JSON.parse(localStorage.getItem('userData'))

  const response = await axios.get(`/api/user/get?id=${userData.id}`, {params, headers: { Authorization: `Bearer ${token}` }})
   console.log(response)
  return {
    params,
    data: response.data.data.alldata,
    totalPages: response.data.data.totalPages,
    totalItems: response.data.data.totalItems
  }
})

export const getUser = createAsyncThunk('appUsers/getUser', async id => {
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  const response = await axios.get('/api/users/user', { id, headers: { Authorization: `Bearer ${token}` } })
  return response.data.user
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch, getState }) => {
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  await axios.post('/apps/users/add-user', user, {headers: { Authorization: `Bearer ${token}` }})
  await dispatch(getData(getState().users.params))
  await dispatch(getAllData())
  return user
})

export const deleteUser = createAsyncThunk('appUsers/deleteUser', (id, { dispatch, getState }) => {
  console.log(id)
   const userdata = JSON.parse(localStorage.getItem('userData'))
  const token = userdata ? userdata.accessToken : ''
  axios.delete('/api/user/delete', { params: {id}, headers: { Authorization: `Bearer ${token}` } })
  .then(response => {
    console.log(response)
    if (response.data.success) {
      alert(`User deleted successfully.`)
      dispatch(getData(getState().users.params))
    } else {
      alert(`Couldn't delete user.`)
    }
  })
  .catch(err => {
    console.log(err)
  })
  // await dispatch(getAllData())
  return id
})

export const appUsersSlice = createSlice({
  name: 'appUsers',
  initialState: {
    data: [],
    total: 0,
    totalItems: 0,
    params: {},
    allData: [],
    filteredData: [],
    filtered: false,
    selectedUser: null
  },
  reducers: {
    filterUsersByDeviceTypeId(state, action) {
      const device_type_id = action.payload.device_type_id

      if (!device_type_id) {
        state.filteredData = []
        state.filtered = false
      } else {
        state.filteredData = state.data.filter(user => {
          return user.device_type_id === device_type_id
        })
  
        state.totalItems = state.data.length
        state.filtered = true
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.totalItems = action.payload.totalItems
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.selectedUser = action.payload
      })
  }
})

export default appUsersSlice.reducer
export const {
  filterUsersByDeviceTypeId
} = appUsersSlice.actions
